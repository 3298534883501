
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        














































































































$product-slider-bp: l;
$option-slider-bp: m;

// Deeps

::v-deep .speedlines__spacer {
  display: none;
}

::v-deep .dominopill-inner {
  position: initial;
}

::v-deep .dominopill__speedlines {
  left: 50%;
}

::v-deep .flickity-viewport {
  width: 100%;
}

::v-deep .services-list__list__item {
  @include mq($until: 's') {
    width: 50%;
  }

  @include mq($until: 'xs') {
    width: 100%;
  }
}

::v-deep .services-list__more-outer {
  display: block;
  text-align: center;

  .services-list__more {
    background: none;

    .btn__link__title {
      color: $c-pink-medium;
    }
  }
}

::v-deep .cta-inner {
  @include mq($until: 's') {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}

::v-deep .cta__btn {
  @include mq($until: 's') {
    margin: 0.5rem 0;
  }
}

::v-deep .cta__excerpt {
  @include mq($until: 's') {
    margin-bottom: 2rem;
  }
}

// Hub Fixe

.hub-fixe__title {
  margin-bottom: 3rem;

  @include mq($from: m) {
    margin-bottom: 5rem;
  }

  @include mq($until: $option-slider-bp) {
    margin-bottom: 5rem;
  }
}

.hub-fixe__title__text {
  text-align: center;
}

.hub-fixe__title__link {
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
}

.hub-fixe__title__link__icon {
  width: auto;
  height: 1.6rem;
  margin-right: 1rem;
}

// Cards

.hub-fixe__card__items {
  position: relative;
}

.hub-fixe__card__item {
  width: 90%;
  max-width: 90%;
  margin: 0 1rem;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  @include mq($from: 'xs', $until: 'xl') {
    width: 30rem;
    min-width: 30rem;
    max-width: none;
    margin: 0 2rem;
  }

  @include mq($from: 'xl') {
    width: 38rem;
    min-width: 38rem;
    max-width: none;
    margin: 0 3rem;
  }
}

// Product cards

.hub-fixe__product__card {
  @include mq($until: $product-slider-bp) {
    /* stylelint-disable-next-line declaration-no-important */
    padding-bottom: 10rem !important;
  }
}

.hub-fixe__product__card__title {
  margin-bottom: 3rem;
  text-align: center;

  @include mq(m) {
    margin-bottom: 5rem;
  }
}

.hub-fixe__product__card__items {
  ::v-deep .switcher {
    .switcher__slider {
      @include mq($until: 'xl') {
        width: 5rem;
      }
    }

    .switcher__option {
      @include mq($until: 'xl') {
        width: 5rem;
        padding: 1rem 0;
      }
    }
  }

  ::v-deep {
    .generic-slider__slides,
    .flickity-slider {
      @include mq($from: $product-slider-bp) {
        display: flex;
        justify-content: center;
        align-items: flex-start;
      }
    }
  }

  ::v-deep .flickity-viewport {
    min-height: 55rem;

    @include mq($until: $product-slider-bp) {
      min-height: 60rem;
      transition: height 0.5s ease;
    }
  }

  ::v-deep .flickity-button {
    @include mq($from: $product-slider-bp) {
      display: none;
    }
  }
}

.hub-fixe__product__card__item {
  @include mq($from: $product-slider-bp) {
    /* stylelint-disable-next-line declaration-no-important */
    position: relative !important;
    /* stylelint-disable-next-line declaration-no-important */
    left: auto !important;
    height: auto;
  }
}

// Option cards

.hub-fixe__option__card {
  @include mq($until: $option-slider-bp) {
    /* stylelint-disable-next-line declaration-no-important */
    padding-bottom: 7rem !important;
  }
}

.hub-fixe__option__card__item {
  height: 100%;

  @include mq($from: $option-slider-bp) {
    /* stylelint-disable-next-line declaration-no-important */
    position: relative !important;
    /* stylelint-disable-next-line declaration-no-important */
    left: auto !important;
    height: auto;
  }
}

// Services List

.hub-fixe__services {
  > div:not(.services-list__list__item__title) {
    @include mq($until: 's') {
      display: none;
    }
  }
}
